import React, { Component } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  overflow: hidden;
  display: block;
`;

const Reveal = styled.span`
  display: inline-block;
  transform: translate(0%, 120%);
  transition: transform ${({ theme }) => theme.effects.transition.medium};
  transition-delay: ${({ delay }) => delay}s;

  ${({ initialized }) =>
    initialized &&
    `
    transform: translate(0, 0);
  `}
`;

const Fade = styled.span`
  opacity: 0;
  transition: opacity ${({ theme }) => theme.effects.transition.medium};
  transition-delay: ${({ delay }) => delay}s;

  ${({ initialized }) =>
    initialized &&
    `
      opacity: 1;
    `}
`;

class Animation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    this.setState({ initialized: true });
  }

  render() {
    const { delay, block, type } = this.props;
    const tag = block ? 'div' : 'span';
    const Element = type === 'reveal' ? Reveal : Fade;

    return (
      <Wrapper as={tag}>
        <Element as={tag} type={type} initialized={this.state.initialized} delay={delay}>
          {this.props.children}
        </Element>
      </Wrapper>
    );
  }
}

export default Animation;
