import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Layout from '../containers/Layout';
import Hero from '../containers/Hero';
import Section from '../components/Section';
import Label from '../components/Label';
import Grid from '../components/Grid';
import styled from 'styled-components';
import Button from '../components/Button';
import Title from '../components/Title';
import ImageResponsiveSquare from '../components/ImageResponsiveSquare';
import Caption from '../components/Caption';
import MissionStatement from '../components/MissionStatement';
import Animation from '../components/Animation';

const HomeCaption = styled(Caption)`
  margin-bottom: 3rem;
  line-height: ${({ theme }) => theme.font.lineHeight.caption};
`;

const SectionTitle = Label.withComponent('h2');

const SectionTitleCenter = styled(SectionTitle)`
  margin-bottom: 3rem;
  text-align: center;
`;

const Box = styled.div`
  text-align: ${props => props.alignContent};
  margin-top: 2rem;
`;

const ButtonLink = Button.withComponent(Link);

const ButtonWithMargin = styled(ButtonLink)`
  margin: 0 3rem 1rem 0;

  &:last-of-type {
    margin-right: 0;
  }
`;

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.projects;

    return (
      <Layout>
        <Hero>
          <Label>
            <Animation type="fade" delay={0.2} initialized>
              Hello there!
            </Animation>
          </Label>
          <Title>
            <Animation type="reveal" delay={0.4} initialized>
              My name is Tomasz Gil.
            </Animation>
            <Animation type="reveal" delay={0.6} initialized>
              I build things for the web.
            </Animation>
          </Title>
          <HomeCaption>
            <Animation type="reveal" delay={1} initialized>
              I'm a software engineer based in Poznań Poland,
            </Animation>
            <Animation type="reveal" delay={1.2} initialized>
              currently working at{' '}
              <a href="https://www.salesloft.com/" target="_blank" rel="noopener noreferrer">
                Salesloft
              </a>
              .
            </Animation>
          </HomeCaption>
          <Animation block delay={2} type="fade" initialized>
            <ButtonWithMargin to="/about">About me</ButtonWithMargin>
            <ButtonWithMargin
              as="a"
              href="https://blog.tomaszgil.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              Read my articles
            </ButtonWithMargin>
          </Animation>
        </Hero>
        <Section>
          <SectionTitleCenter>Featured projects</SectionTitleCenter>
          <Grid cols={2}>
            {posts.map(({ node: post }) => (
              <Link key={post.id} to={post.fields.slug}>
                <ImageResponsiveSquare
                  imgSrc={post.frontmatter.thumbnail}
                  title={post.frontmatter.title}
                  subtitle={post.frontmatter.tags && post.frontmatter.tags.join(', ')}
                />
              </Link>
            ))}
          </Grid>
          <Box alignContent={'right'}>
            <ButtonLink to="/projects">See more projects</ButtonLink>
          </Box>
        </Section>
      </Layout>
    );
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export const pageQuery = graphql`
  query IndexQuery {
    projects: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "case-study" } } }
      limit: 4
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            thumbnail
            tags
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
