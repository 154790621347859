import styled from 'styled-components';

const MissionStatement = styled.p`
  color: ${({ theme }) => theme.color.text.primary};
  max-width: 45rem;
  font-family: ${({ theme }) => theme.font.family.bold};
  font-size: ${({ theme }) => theme.font.size.heading.secondary};
  margin: 0 auto;
  text-align: center;
  line-height: ${({ theme }) => theme.font.lineHeight.caption};

  ${({theme}) => theme.media.tablet`
    font-size: ${({ theme }) => theme.font.size.heading.tertiary};
    margin: 1rem auto;
  `}
`;

export default MissionStatement;
